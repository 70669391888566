<div class="flex flex-col flex-1 overflow-auto pb-28" #messagesContainer>
	<div class="w-full p-6 space-y-6">
		<div *ngIf="messages?.length > 0">
			<div *ngFor="let message of messages;let i = index; let first = first; let last = last; trackBy: trackByFn" class="flex flex-col gap-8 mb-2 w-full">
				<ng-container [ngTemplateOutlet]="message.type === 'user' ? userMessageTemplate : agentMessageTemplate" [ngTemplateOutletContext]="{data: message, last: last}"></ng-container>
			</div>
		</div>
	</div>
</div>

<!--User message template-->
<ng-template #userMessageTemplate let-msg="data">
	<div class="flex w-full justify-end" dir="auto" data-testid="conversation-turn-2" data-scroll-anchor="false">
		<div class="justify-end text-right rounded-xl px-6 py-2 dark:bg-gray-500 bg-gray-200">
			{{ msg.content }}
		</div>
	</div>
</ng-template>

<!--flowBot message template-->
<ng-template #agentMessageTemplate let-msg="data" let-last="last">
	<div class="w-full " dir="auto" data-testid="conversation-turn-2" data-scroll-anchor="false">
		<div class="py-2 px-3 text-base md:px-4 m-auto lg:px-1 xl:px-5">
			<div
				class="mx-auto flex flex-1 gap-6 text-base md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
				<div class="flex-shrink-0 flex flex-col relative items-end">
					<div>
						<div class="pt-0.5">
							<div
								class="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full">
								<div class="">
									<img src="images/flowbot-static-icon.svg" class="w-8 h-8">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="relative flex w-full min-w-0 flex-col">
					<div class="flex-col gap-1 md:gap-3">
						<div class="flex flex-grow flex-col max-w-full">
							<div data-message-author-role="user" data-message-id=""
								 class="min-h-[20px] text-message flex flex-col items-start whitespace-pre-wrap break-words overflow-x-auto gap-3 w-full pb-2">
								@if(loading && last){
									<div class="flex flex-row gap-2 h-6 items-center pl-4">
										<span class="relative flex h-1 w-1">
										  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
										  <span class="relative inline-flex rounded-full h-1 w-1 bg-sky-500"></span>
										</span>
										<span class="relative flex h-1 w-1">
										  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
										  <span class="relative inline-flex rounded-full h-1 w-1 bg-sky-500"></span>
										</span>
										<span class="relative flex h-1 w-1">
										  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
										  <span class="relative inline-flex rounded-full h-1 w-1 bg-sky-500"></span>
										</span>
									</div>
								}
                                @else {
									<div  class="chat-group" [innerHTML]="msg?.content | safeHtml"></div>
									@if(last){
										<flowbot-chat-actions [messageContent]="msg?.content" [isAutoPlay]="false"/>
                                        <flowbot-sources [sources]="sources()"/>
                                        <flowbot-suggestions [questionSuggestions]="suggestions()" (suggestionClicked)="emitSuggestionChat($event)"/>
									}
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>


