import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import {AuthService} from "../services/auth/auth.service";

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
	const router: Router = inject(Router);

    return inject(AuthService).determineRefreshNeeded().pipe(
        switchMap((authenticated: boolean) =>
        {
            // If the user is not authenticated...
            if ( !authenticated )
            {
                // Redirect to the sign-in page with a redirectUrl param

                const urlTree = router.parseUrl(`tenant-picker`);
                console.log(urlTree);
                return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
};
