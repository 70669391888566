import { Component } from '@angular/core';

@Component({
  selector: 'most-used',
  standalone: true,
  imports: [],
  templateUrl: './menu-most-used.component.html',
  styleUrl: './menu-most-used.component.scss'
})
export class MenuMostUsedComponent {

}
