<div class="relative flex flex-col w-full h-full">
	<!-- Toolbar (Sticky at the top) -->
	<flowbot-toolbar class="sticky top-0 z-10" (fullChatMode)="navigateToFullChat()"/>

	<!-- Messages (Scrollable content) -->
	<div class="flex-1 overflow-y-auto" #messageContainer>
		@if (messages.length > 0) {
			<flowbot-messages [messages]="messages" [loading]="loading"
                              [suggestions]="questionSuggestions"
                              [sources]="sources"
                              (emitChatContent)="chatRequest($event)"
            />
           <!-- Place Suggestions Here-->
		} @else {

			<new-chat-hints />
		}
	</div>

	<!-- Input (Sticky at the bottom) -->
	<div class="sticky bottom-0 w-full px-6 pb-4">
		<flowbot-input class="sticky bottom-0" (newMessage)="chatRequest($event)" [disabled]="disabled"/>
	</div>
</div>





