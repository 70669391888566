<!-- Loading bar -->
<flow-loading-bar></flow-loading-bar>
<!--<app-notifier></app-notifier>-->
<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 bg-gradient-to-tr dark:from-[#435267] dark:to-[#1E293B] from-[#F6F7F9] to-[#E9EDF4]">
	<mat-drawer-container class="flex-auto h-full w-full">
		<mat-drawer #flowBot
					[opened]="drawerOpened"
					position="end"
					class="max-h-screen bg-gradient-to-tr dark:from-[#1E293B] dark:to-[#435267] from-[#E1E9F2] to-[#F6F7F9] rounded-l-2xl w-full md:w-1/2"
					disableClose>
			@if(drawerOpened) {
				<flowbot-chat-dialog/>
			}
		</mat-drawer>

		<mat-drawer-content class="flex flex-col overflow-hidden h-screen">
			<!-- Header -->
			<div class="relative flex flex-0 items-center w-full h-20 px-4 md:px-6 z-49 print:hidden">
				<!-- Logo and buttons -->
				<div class="flex items-center pr-2 space-x-2">
					<a routerLink="./">
						<img class="w-24" [src]="logo" alt="Logo image">
					</a>
                    <button
                        class="flex md:hidden"
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                    </button>
				</div>
				<!-- Components -->
				<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
					<flowbot-button (onClick)="toggleDrawerOpen()" *ngIf="!isTargetRoute"/>
					<flow-fullscreen class="hidden md:block"></flow-fullscreen>
					<most-used/>
					<message-center/>
					<menu-user/>
				</div>
			</div>

			<!-- Main Content -->
			<div class="flex flex-row h-full">
				<!-- Sidebar -->
				<flow-vertical-navigation
					class=" print:hidden"
					[appearance]="navigationAppearance"
					[mode]="isScreenSmall ? 'over' : 'side'"
					[name]="'mainNavigation'"
					[navigation]="navigation"
					[opened]="!isScreenSmall">
					<!-- Navigation header hook -->
				</flow-vertical-navigation>

				<!-- Router Content -->
				<div class="flex flex-col overflow-y-auto min-h-[calc(100vh-theme(space.20))] md:w-[calc(100vw-theme(space.20))] md:max-w-[calc(100vw-theme(space.20))] w-screen bg-gradient-to-tr dark:from-[#1E293B] dark:to-[#435267] from-[#E1E9F2] to-[#F6F7F9] rounded-tl-3xl pb-24">
					@if (true) {
						<router-outlet></router-outlet>
					}
				</div>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>
</div>
