import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconsService {
	/**
	 * Constructor
	 */
	constructor() {
		const domSanitizer = inject(DomSanitizer);
		const matIconRegistry = inject(MatIconRegistry);

		// Register icon sets
		matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/icons/material-twotone.svg'));
		matIconRegistry.addSvgIconSetInNamespace('mat_outline', domSanitizer.bypassSecurityTrustResourceUrl('/icons/material-outline.svg'));
		matIconRegistry.addSvgIconSetInNamespace('mat_solid', domSanitizer.bypassSecurityTrustResourceUrl('/icons/material-solid.svg'));
		matIconRegistry.addSvgIconSetInNamespace('feather', domSanitizer.bypassSecurityTrustResourceUrl('/icons/feather.svg'));
		matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', domSanitizer.bypassSecurityTrustResourceUrl('/icons/heroicons-outline.svg'));
		matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', domSanitizer.bypassSecurityTrustResourceUrl('/icons/heroicons-solid.svg'));
		matIconRegistry.addSvgIconSetInNamespace('heroicons_mini', domSanitizer.bypassSecurityTrustResourceUrl('/icons/heroicons-mini.svg'));
		matIconRegistry.addSvgIconSetInNamespace('flow', domSanitizer.bypassSecurityTrustResourceUrl('/icons/flow/flow.svg'));
		matIconRegistry.addSvgIcon('flow-bot', domSanitizer.bypassSecurityTrustResourceUrl('/icons/flow/flow-bot.svg'));
		matIconRegistry.addSvgIcon('outlook', domSanitizer.bypassSecurityTrustResourceUrl('/icons/flow/outlook.svg'));
	}
}
