import { Injectable } from '@angular/core';
import { AuthService } from '@flow/services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class WebSocketAuthService {
    constructor(private authService: AuthService) {}

    async getConnectionParams(): Promise<{ Authorization: string }> {
        const authenticated = await this.authService.determineRefreshNeeded()
            .toPromise();
        if (!authenticated) {
            throw new Error('Session expired. Please log in again.');
        }
        return { Authorization: `Bearer ${this.authService.accessToken}` };
    }
}
