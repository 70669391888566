import {
    ChangeDetectorRef,
    Component, effect,
    ElementRef, EventEmitter, input,
    Input,
    InputSignal,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {FlowbotChatActionsComponent} from "../flowbot-chat-actions/flowbot-chat-actions.component";
import { SafeHtmlPipe } from 'primeng/menu';
import { FlowbotSuggestionsComponent } from '../flowbot-suggestions/flowbot-suggestions.component';
import { FlowbotSourcesComponent } from '../flowbot-sources/flowbot-sources.component';
export interface Message {
  type: string;
  content: string;
}
@Component({
    selector: 'flowbot-messages',
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        NgTemplateOutlet,
        FlowbotChatActionsComponent,
        SafeHtmlPipe,
        FlowbotSuggestionsComponent,
        FlowbotSourcesComponent,
    ],
    templateUrl: './flowbot-messages.component.html',
    styleUrl: './flowbot-messages.component.scss',
})
export class FlowbotMessagesComponent implements OnInit, OnDestroy, OnChanges {
    @Input() messages: Message[];
    @Input() loading: boolean = false;
    suggestions: InputSignal<any> = input.required<any>();
    sources: InputSignal<any> = input.required<any>();
    @Output() emitChatContent: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('messagesContainer') messagesContainer: ElementRef;

    constructor(private cdr: ChangeDetectorRef) {
        effect(() => {
            this.cdr.detectChanges();
        });
    }

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['loading']) {
            this.loading = changes['loading'].currentValue;
            this.cdr.detectChanges();
        }
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        setTimeout(() => {
            this.messagesContainer.nativeElement.scrollTop =
                this.messagesContainer.nativeElement.scrollHeight;
        }, 0);
    }

    /**
     * Tracks indexes in arrays
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    emitSuggestionChat(content: any) {

        this.emitChatContent.emit(content);
    }
}
