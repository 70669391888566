<div class="flow-vertical-navigation-wrapper">

    <!-- Header -->
    <div class="flow-vertical-navigation-header">
        <ng-content select="[flowVerticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div
        class="flow-vertical-navigation-content"
        flowScrollbar
        [flowScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}"
        #navigationContent>

        <!-- Content header -->
        <div class="flow-vertical-navigation-content-header">
            <ng-content select="[flowVerticalNavigationContentHeader]"></ng-content>
        </div>

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Skip if user does not have permissions -->
                <ng-container >

                    <!-- Aside -->
                    <ng-container *ngIf="item.type === 'aside'">
                        <flow-vertical-navigation-aside-item
                                [item]="item"
                                [name]="name"
                                [activeItemId]="activeAsideItemId"
                                [autoCollapse]="autoCollapse"
                                [skipChildren]="true"
                                (click)="toggleAside(item)"></flow-vertical-navigation-aside-item>
                    </ng-container>

                    <!-- Basic -->
                    <ng-container *ngIf="item.type === 'basic'">
                        <flow-vertical-navigation-basic-item
                                [item]="item"
                                [name]="name"></flow-vertical-navigation-basic-item>
                    </ng-container>

                    <!-- Collapsable -->
                    <ng-container *ngIf="item.type === 'collapsable'">
                        <flow-vertical-navigation-collapsable-item
                                [item]="item"
                                [name]="name"
                                [autoCollapse]="autoCollapse"></flow-vertical-navigation-collapsable-item>
                    </ng-container>

                    <!-- Divider -->
                    <ng-container *ngIf="item.type === 'divider'">
                        <flow-vertical-navigation-divider-item
                                [item]="item"
                                [name]="name"></flow-vertical-navigation-divider-item>
                    </ng-container>

                    <!-- Group -->
                    <ng-container *ngIf="item.type === 'group'">
                        <flow-vertical-navigation-group-item
                                [item]="item"
                                [name]="name"
                                [autoCollapse]="autoCollapse"></flow-vertical-navigation-group-item>
                    </ng-container>

                    <!-- Spacer -->
                    <!--<ng-container *ngIf="item.type === 'spacer'">
                        <flow-vertical-navigation-spacer-item
                                [item]="item"
                                [name]="name"></flow-vertical-navigation-spacer-item>
                    </ng-container>-->
                </ng-container>

            </ng-container>

        </ng-container>

        <!-- Content footer -->
        <div class="flow-vertical-navigation-content-footer">
            <ng-content select="[flowVerticalNavigationContentFooter]"></ng-content>
        </div>

    </div>

    <!-- Footer -->
    <div class="flow-vertical-navigation-footer">
        <ng-content select="[flowVerticalNavigationFooter]"></ng-content>
    </div>

</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
    <div
        class="flow-vertical-navigation-aside-wrapper"
        flowScrollbar
        [flowScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}"
        [@fadeInLeft]="position === 'left'"
        [@fadeInRight]="position === 'right'"
        [@fadeOutLeft]="position === 'left'"
        [@fadeOutRight]="position === 'right'">

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
                    <flow-vertical-navigation-aside-item
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"></flow-vertical-navigation-aside-item>
                </ng-container>

            </ng-container>

        </ng-container>

    </div>
</ng-container>
