import {inject} from '@angular/core';
import {CanActivateChildFn, CanActivateFn, Router} from '@angular/router';
import {of, switchMap} from 'rxjs';
import {AuthService} from "../services/auth/auth.service";

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
	const router: Router = inject(Router);
	const authService = inject(AuthService);

	// Check and refresh tokens
	return authService.determineRefreshNeeded().pipe(
		switchMap((authenticated) => {
			// If the user is authenticated, redirect to the default route
			if (authenticated) {
				return of(router.parseUrl(''));
			}

			// If the user is not authenticated, allow access to the route
			return of(true);
		})
	);
};

