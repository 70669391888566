import {Injectable, NgZone} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VoiceRecognitionService {
    // @ts-ignore
    private recognition: SpeechRecognition;
    private readonly synth: SpeechSynthesisUtterance;
    isUserSpeaking: boolean = false;

    constructor(private zone: NgZone) {
        // @ts-ignore
        const SpeechRecognition = window.SpeechRecognition || (window as any).webkitSpeechRecognition;
        this.synth = new SpeechSynthesisUtterance();

        if (SpeechRecognition) {
            this.recognition = new SpeechRecognition();
            this.recognition.continuous = true;
            this.recognition.interimResults = true;  // Provides real-time transcription results
        } else {
            console.error('Browser does not support Speech Recognition.');
        }
    }

    initialize(onResultCallback: (transcript: string, isFinal: boolean) => void): void {
        if (!this.recognition) return;

        this.recognition.onresult = (event: { results: any; resultIndex: any; }) => {
            const results = event.results;
            const current = event.resultIndex;

            const transcript = results[current][0].transcript;
            const isFinal = results[current].isFinal;  // Check if the current result is final

            this.zone.run(() => onResultCallback(transcript, isFinal));
        };
    }

    start(): void {
        if (this.recognition && !this.isUserSpeaking) {
            this.recognition.start();
            this.isUserSpeaking = true;
        }
    }

    stop(): void {
        if (this.recognition && this.isUserSpeaking) {
            this.recognition.stop();
            this.isUserSpeaking = false;
        }
    }

    speak(text: string): void {

        if (!speechSynthesis) {
            console.error('Browser does not support Speech Synthesis.');
            return;
        }
		this.synth.rate = 1;
		this.synth.pitch = 1;

        // Stop the current speech synthesis before starting a new one
        if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
        }

        this.synth.text = text;
        speechSynthesis.speak(this.synth);
    }

    sanitizeText(text: string): string {
        // Example: Remove problematic punctuation, adjust as needed for other issues
        return text.replace(/\./g, ' ');
    }

	/**
	 * Retrieves the available voices from the browser.
	 * @returns A list of SpeechSynthesisVoice objects.
	 */
	/*getAvailableVoices(): SpeechSynthesisVoice[] {
		/!*return this.synth.getVoices();*!/
	}*/

}
