<!-- Sticky Header Wraper-->
<div class="sticky top-0 z-10 flex flex-row w-full items-center p-2 px-6">
	<!-- Inner Wrapper -->
	<div class="flex flex-row w-full items-center ">
		<div class="text-xl leading-6 font-bold text-left w-full flex flex-row items-center ">
			<img src="images/flowbot-static-icon.svg" class="h-6 w-6 mr-1" alt="flow logo"/>
			flowBot
			<span class="bg-primary rounded px-2 py-1 text-xs leading-none ml-3 text-white">Beta</span>
		</div>
		<div class="flex flex-row gap-1">
			<!-- Buttons -->
			<!--<button mat-icon-button [matTooltip]="'Open flowBot in chat mode'" (click)="navigateToFullMode()">
				<mat-icon
						  [svgIcon]="'heroicons_outline:arrow-top-right-on-square'"></mat-icon>
			</button>
			<flowbot-settings/>-->
			<button mat-icon-button (click)="toggleDrawer()" [matTooltip]="'Close flowBot'">
				<mat-icon
						  [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
			</button>
		</div>
	</div>
</div>
