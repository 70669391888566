import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FlowSplashScreenService } from '@flow/services';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet],
    standalone: true,
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(private _flowSplashScreenService: FlowSplashScreenService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private titleService: Title,
                private primeng: PrimeNG) {
                    /*this.primeng.theme.set({
                        preset: Aura,
                        options: {

                            cssLayer: {
                                name: 'primeng',
                                order: 'tailwind-base, primeng, tailwind-utilities'
                            }
                        }
                    })*/
    }


    ngOnInit() {
        this._flowSplashScreenService.hide();
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                map((route) => 'flowRMS | ' + route.snapshot.data['title'] || 'Rep Management, made easy')
            )
            .subscribe((title: string) => {
                this.titleService.setTitle(title);
            });
    }
}
