import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForOf } from '@angular/common';

@Component({
    selector: 'flowbot-sources',
    imports: [NgForOf],
    templateUrl: './flowbot-sources.component.html',
    styleUrl: './flowbot-sources.component.scss',
})
export class FlowbotSourcesComponent {
    @Input() sources: { fileName: string; pageNumber: number }[] = [];
    @Output() sourceClicked = new EventEmitter<number>();

    onSourceClick(pageNumber: number): void {
        this.sourceClicked.emit(pageNumber);
    }
}
