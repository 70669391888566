import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import {HotkeyService} from "../../services/hotkey/hotkey.service";


@Directive({
	standalone: true,
	selector: '[flowHotkey]'
})
export class HotkeyDirective implements OnInit {
	@Input('flowHotkey') hotkey!: string;

	constructor(
		private el: ElementRef,
		private hotkeyService: HotkeyService
	) {

	}

	ngOnInit() {
		const modifier = this.hotkeyService.getModifierKey();
		this.el.nativeElement.innerText += `${modifier}+${this.hotkey}`;
	}

	@HostListener('window:keydown', ['$event'])
	handleKeydown(event: KeyboardEvent) {
		const modifier = this.hotkeyService.getModifierKey();
		const keyCombination = `${modifier} + ${this.hotkey}`.toLowerCase();
		if (event.key.toLowerCase() === this.hotkey.toLowerCase() &&
			(modifier === '⌘' ? event.metaKey : event.ctrlKey)) {
			event.preventDefault();
			this.el.nativeElement.click();
		}
	}
}
