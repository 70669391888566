import { Component } from '@angular/core';
import {FlowMenuButtonComponent} from "@flow/components/flow-menu-button";
import {MenuConfig} from "@flow/components/flow-menu-button/flow-menu-button.types";
import {Router} from "@angular/router";

@Component({
  selector: 'menu-user',
  standalone: true,
	imports: [
		FlowMenuButtonComponent
	],
  templateUrl: './menu-user.component.html',
  styleUrl: './menu-user.component.scss'
})
export class MenuUserComponent {
	menuConfig: MenuConfig = {
		buttonType: 'matIconButton',
		buttonImageSrc: '',
		items: [
			/*{ icon: 'account_circle', text: 'Profile', action: this.goToProfile, type: 'normal' },*/
			{ icon: 'support_agent', text: 'Support', action: this.goToSupport, type: 'normal' },
			{ icon: 'mediation', text: 'Roadmap', action: this.goToRoadMap, type: 'normal' },
			{
				type: 'divider',
			},
			{ icon: 'logout', text: 'Sign Out', action: this.onSignOut, type: 'normal' }
		]
	};
	imageSrc: string = '';
	constructor(private _router: Router) {
		const firstName = localStorage.getItem('user_first_name') || '';
		const lastName = localStorage.getItem('user_last_name') || '';

		const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
		this.imageSrc = this.generateInitialsImage(initials, 50, 50);
		this.menuConfig.buttonImageSrc = this.imageSrc;
	}

	goToProfile(): void{

	}

	goToSupport(): void{

	}

	goToRoadMap(): void{

	}

	onSignOut(): void{
		console.log('onSignOut');
		this._router.navigate(['/sign-out']);
	}


	triggerEvent(event: any) {


		if(event.name === 'onSignOut'){
			this.onSignOut();
		}
	}

	generateInitialsImage(initials: string, width: number, height: number): string {
		// Create a canvas element
		const canvas = document.createElement('canvas');
		canvas.width = width;
		canvas.height = height;

		const context = canvas.getContext('2d');
		if (!context) return '';

		// Draw a background
		context.fillStyle = '#4338ca';
		context.fillRect(0, 0, width, height);

		// Set text styles
		context.font = `${width / 3}px Arial`;
		context.textAlign = 'center';
		context.textBaseline = 'middle';
		context.fillStyle = '#e5e7eb'; // Example text color

		// Draw the initials
		context.fillText(initials, width / 2, height / 2);

		// Convert the canvas to a base64 image URL
		return canvas.toDataURL('image/png');
	}
}
