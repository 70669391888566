import {BooleanInput} from '@angular/cdk/coercion';
import {NgFor, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {Subject, takeUntil} from 'rxjs';
import {FlowVerticalNavigationBasicItemComponent} from "../basic/basic.component";
import {
  FlowVerticalNavigationDividerItemComponent
} from "../divider/divider.component";
import {FlowVerticalNavigationSpacerItemComponent} from "../spacer/spacer.component";
import * as navigationTypes from "../../../navigation.types";
import {FlowVerticalNavigationComponent} from "../../vertical.component";
import {FlowNavigationService} from "../../../navigation.service";

@Component({
  selector: 'flow-vertical-navigation-group-item',
  templateUrl: './group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatIconModule, NgFor,
    FlowVerticalNavigationBasicItemComponent,
    FlowVerticalNavigationDividerItemComponent,
    FlowVerticalNavigationSpacerItemComponent],
})
export class FlowVerticalNavigationGroupItemComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_autoCollapse: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() autoCollapse: boolean;
  @Input() item: navigationTypes.FlowNavigationItem;
  @Input() name: string;

  private _flowVerticalNavigationComponent: FlowVerticalNavigationComponent;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _flowNavigationService: FlowNavigationService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the parent navigation component
    this._flowVerticalNavigationComponent = this._flowNavigationService.getComponent(this.name);

    // Subscribe to onRefreshed on the navigation component
    this._flowVerticalNavigationComponent.onRefreshed.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(() => {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
