import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { IconsService } from '@flow/services/icons';



export const provideIcons = (): (Provider | EnvironmentProviders)[] => {
	return [
		{
			provide: ENVIRONMENT_INITIALIZER,
			useValue: () => inject(IconsService),
			multi: true,
		},
	];
};
