import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    MatDrawer,
    MatDrawerContainer,
    MatDrawerContent,
} from '@angular/material/sidenav';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterLink,
    RouterOutlet,
} from '@angular/router';
import { FlowDrawerMode } from '@flow/components/drawer';
import { FlowFullscreenComponent } from '@flow/components/fullscreen/fullscreen.component';
import { FlowConfigService } from '@flow/services/config/config.service';
import { FlowMediaWatcherService } from '@flow/services/media-watcher/media-watcher.service';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';

import { NgIf } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MenuMostUsedComponent } from '@app/routes/admin/users/components/menu-most-used/menu-most-used.component';
import { MenuUserComponent } from '@app/routes/admin/users/components/menu-user/menu-user.component';
import { MessageCenterComponent } from '@app/routes/admin/users/components/message-center/message-center.component';
import { FlowLoadingBarComponent } from '@flow/components/loading-bar';
import { FlowNavigationService } from '@flow/navigation/navigation.service';
import { FlowNavigationItem } from '@flow/navigation/navigation.types';
import { FlowVerticalNavigationComponent } from '@flow/navigation/vertical/vertical.component';
import { FlowbotDrawerService, StorageService } from '@flow/services';
import {
    FlowbotButtonComponent
} from '@app/routes/admin/flowbot/components/chat/flowbot-button/flowbot-button.component';
import { FlowbotChatComponent } from '@app/routes/admin/flowbot/components/chat/flowbot-chat/flowbot-chat.component';

@Component({
    selector: 'main-layout',
    standalone: true,
    imports: [
        RouterOutlet,
        FlowFullscreenComponent,
        MenuUserComponent,
        MessageCenterComponent,
        MenuMostUsedComponent,
        FlowbotButtonComponent,
        RouterLink,
        MatDrawerContainer,
        MatDrawer,
        MatDrawerContent,
        NgIf,
        FlowVerticalNavigationComponent,
        FlowbotChatComponent,
        FlowLoadingBarComponent,
        MatIconButton,
        MatIcon,
    ],
    templateUrl: './main-layout.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .main-nav::before {
            }
        `,
    ],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: FlowNavigationItem[];
    navigationAppearance: 'default' | 'dense' = 'dense';
    drawerMode: FlowDrawerMode;
    drawerOpened: boolean;
    isTargetRoute = false;
    logo: string = 'images/logo-dark.png';

    protected scheme: 'dark' | 'light';
    private routeSub: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private drawerSubscription: Subscription;
    private _flowConfigService = inject(FlowConfigService);
    private _storageService = inject(StorageService);

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: FlowNavigationService,
        private _flowMediaWatcherService: FlowMediaWatcherService,
        private _flowNavigationService: FlowNavigationService,
        private _flowDrawerService: FlowbotDrawerService,
        private cdr: ChangeDetectorRef
    ) {
        this.drawerOpened = false;
        this.navigation = [
            {
                id: 'dashboard',
                title: 'Dashboard',
                subtitle: 'Plan your day, track your sales.',
                type: 'basic',
                icon: 'empty_dashboard',
                link: '/',
                roles: [],
            },
            {
                type: 'group',
                children: [
                    {
                        id: 'quotes',
                        title: 'Quotes',
                        subtitle: 'Manage all your quotes',
                        type: 'basic',
                        link: '/crm/quotes/list',
                        icon: 'request_quote',
                        roles: [],
                    },

                    {
                        id: 'contacts',
                        title: 'Contacts',
                        subtitle: 'Manage all your contacts',
                        type: 'basic',
                        icon: 'contacts',
                        link: '/crm/contacts/list',
                        roles: [],
                    },
                ],
            },
            {
                type: 'group',
                children: [
                    {
                        id: 'orders',
                        title: 'Orders',
                        subtitle: 'Manage all your orders',
                        type: 'basic',
                        link: '/cm/orders/list',
                        icon: 'orders',
                        roles: [],
                    },

                    {
                        id: 'invoices',
                        title: 'Invoices',
                        subtitle: 'Manage all your invoices',
                        type: 'basic',
                        icon: 'receipt_long',
                        link: '/cm/invoices/list',
                        roles: [],
                    },
                    {
                        id: 'commissions',
                        title: 'Commissions',
                        subtitle: 'Manage all your commission payments',
                        type: 'basic',
                        icon: 'wallet',
                        link: '/core/commissions/list',
                        roles: [],
                    },
                ],
            },
            {
                type: 'group',
                children: [
                    {
                        id: 'products',
                        title: 'Products',
                        subtitle: 'Manage all your products',
                        type: 'basic',
                        link: '/core/products/list',
                        icon: 'category',
                        roles: [],
                    },

                    {
                        id: 'customers',
                        title: 'Customers',
                        subtitle: 'Manage all your customers',
                        type: 'basic',
                        icon: 'store',
                        link: '/core/customers/list',
                        roles: [],
                    },
                    {
                        id: 'factories',
                        title: 'Factories',
                        subtitle: 'Manage all your factories',
                        type: 'basic',
                        icon: 'factory',
                        link: '/core/factories/list',
                        roles: [],
                    },
                ],
            },
            {
                type: 'group',
                children: [
                    {
                        id: 'fileManager',
                        title: 'File Manager',
                        subtitle: 'Manage all your files in one place',
                        type: 'basic',
                        icon: 'folder_open',
                        link: '/file-manager',
                        roles: [],
                    },
                    {
                        id: 'flowbot-dashboard',
                        title: 'flowBot Dashboard',
                        subtitle: 'Manage all your processed files',
                        type: 'basic',
                        icon: 'flowchart',
                        link: '/flowbot/dashboard',
                        roles: [],
                    },
                ],
            },
        ];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.logo = this.logo =
            'images/logo-' + this._storageService.get('scheme') + '.png';

        // Subscribe to navigation data
        /*this._navigationService.navigation$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((navigation: Navigation) => {

				this.navigation = navigation;

			});*/

        // Subscribe to media changes

        this._flowMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            // @ts-ignore
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');

                // Change the navigation appearance
                this.navigationAppearance = this.isScreenSmall
                    ? 'default'
                    : 'dense';
                this.cdr.detectChanges();
            });

        this.drawerSubscription =
            this._flowDrawerService.drawerState$.subscribe(
                (isOpen: boolean) => {
                    console.log(isOpen);
                    this.drawerOpened = isOpen;
                    this.cdr.detectChanges();
                }
            );
        // Check on initial load or refresh
        this.checkRoute();

        // Listen to route changes
        this.routeSub = this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.checkRoute();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._flowNavigationService.getComponent<FlowVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        this.navigationAppearance =
            this.navigationAppearance === 'default' ? 'dense' : 'default';
    }

    /**
     * Toggle the drawer open
     */
    toggleDrawerOpen(): void {
        this._flowDrawerService.toggleDrawer();
    }

    private checkRoute(): void {
        //this.isTargetRoute =
        //this._activatedRoute.snapshot?._routerState === 'flowbot';
    }
}
