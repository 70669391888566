<button mat-icon-button [matMenuTriggerFor]="menu">
	@if (menuConfig.buttonType === 'matIconButton') {
		@if (menuConfig.buttonImageSrc) {
			<img [src]="menuConfig.buttonImageSrc" alt="Button" class="rounded-full h-full w-full">
		} @else {
			<mat-icon [svgIcon]="menuConfig.buttonIcon || 'user'"></mat-icon>
		}
	} @else if (menuConfig.buttonType === 'matSolidButton' || menuConfig.buttonType === 'matStrokeButton') {
		@if (menuConfig.buttonIcon) {
			<mat-icon [svgIcon]="menuConfig.buttonIcon || 'user'"></mat-icon>
		}
		{{ menuConfig.buttonText }}
	}
</button>

<mat-menu #menu="matMenu">
	@for (item of menuConfig.items; track item) {
		@if (item.type === 'normal') {
			<button mat-menu-item (click)="triggerClickEvent(item.action)">
				@if (item.icon) {
					<mat-icon class="icon-size-4" [svgIcon]="item.icon || 'user'"></mat-icon>
				}
				<span>{{ item.text }}</span>
			</button>
		} @else if (item.type === 'divider') {
			<mat-divider></mat-divider>
		}
	}
</mat-menu>

