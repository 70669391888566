import {Component, EventEmitter, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";

import {FlowbotSettingsComponent} from "../flowbot-settings/flowbot-settings.component";
import {FlowbotDrawerService} from '@flow/services';

@Component({
  selector: 'flowbot-toolbar',
  standalone: true,
	imports: [
		MatIcon,
		MatIconButton,
		MatTooltip,

	],
  templateUrl: './flowbot-toolbar.component.html',
  styleUrl: './flowbot-toolbar.component.scss'
})
export class FlowbotToolbarComponent {
	@Output() fullChatMode: EventEmitter<void> = new EventEmitter();

	constructor(
		private _flowbotDrawerService: FlowbotDrawerService,
	) {
	}

	toggleDrawer(): void {
		this._flowbotDrawerService.toggleDrawer();
	}


	navigateToFullMode() {
		this.fullChatMode.emit();
	}
}
