import { Component } from '@angular/core';

@Component({
  selector: 'message-center',
  standalone: true,
  imports: [],
  templateUrl: './message-center.component.html',
  styleUrl: './message-center.component.scss'
})
export class MessageCenterComponent {

}
