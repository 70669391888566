<ng-container *ngFor="let item of item.children; trackBy: trackByFn">

    <!-- Skip the hidden items -->
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic'">
            <flow-vertical-navigation-basic-item
                [item]="item"
                [name]="name"></flow-vertical-navigation-basic-item>
        </ng-container>

        <!-- Collapsable -->
        <ng-container *ngIf="item.type === 'collapsable'">
            <flow-vertical-navigation-collapsable-item
                [item]="item"
                [name]="name"
                [autoCollapse]="autoCollapse"></flow-vertical-navigation-collapsable-item>
        </ng-container>

        <!-- Divider -->
        <ng-container *ngIf="item.type === 'divider'">
            <flow-vertical-navigation-divider-item
                [item]="item"
                [name]="name"></flow-vertical-navigation-divider-item>
        </ng-container>

        <!-- Group -->
        <!--<ng-container *ngIf="item.type === 'group'">
            <flow-vertical-navigation-group-item
                [item]="item"
                [name]="name"></flow-vertical-navigation-group-item>
        </ng-container>-->

        <!-- Spacer -->
        <ng-container *ngIf="item.type === 'spacer'">
            <flow-vertical-navigation-spacer-item
                [item]="item"
                [name]="name"></flow-vertical-navigation-spacer-item>
        </ng-container>

    </ng-container>

</ng-container>
