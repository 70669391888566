import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { NgForOf } from '@angular/common';

@Component({
    selector: 'flowbot-suggestions',
    imports: [MatButton, NgForOf],
    templateUrl: './flowbot-suggestions.component.html',
    styleUrl: './flowbot-suggestions.component.scss',
})
export class FlowbotSuggestionsComponent {
    @Input() questionSuggestions: { content: string; title: string }[] = [];
    @Output() suggestionClicked = new EventEmitter<string>();

    onSuggestionClick(content: string): void {
        console.log(content);
        this.suggestionClicked.emit(content);

    }
}
