<div class="w-full flex ">
	<mat-form-field matPrefix class="w-full" subscriptSizing="dynamic">
		<div class="justify-start" matPrefix>
			<button mat-icon-button>
				<mat-icon [svgIcon]="'mat_solid:attach_file'" class="icon-size-5"></mat-icon>
			</button>
		</div>
		<textarea
			matInput
			autofocus
			#inputField
            [disabled]="disabled()"
			(keyup.enter)="sendMessage()"
			(input)="onInput(); adjustHeight($event)"
			class="grow ml-2 resize-none upward-grow max-h-60"
			[placeholder]="placeHolderText"
			type="text"></textarea>

		<div class="justify-end" matSuffix>
			<!-- Show mic buttons if the user is not typing -->
			<button mat-icon-button *ngIf="!hasTyped && !isUserSpeaking" (click)="startRecording()">
				<mat-icon [svgIcon]="'mat_outline:mic'" class="icon-size-5"></mat-icon>
			</button>
			<button mat-icon-button *ngIf="!hasTyped && isUserSpeaking" (click)="stopRecording()">
				<mat-icon [svgIcon]="'mat_outline:mic_off'" class="icon-size-5"></mat-icon>
			</button>

			<!-- Show new button if the user types -->
			<button mat-icon-button *ngIf="hasTyped" (click)="sendMessage()">
				<mat-icon [svgIcon]="'mat_outline:send'" class="icon-size-5"></mat-icon>
			</button>
		</div>
	</mat-form-field>
</div>
