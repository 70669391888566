import {Component, inject, input, InputSignal} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {VoiceRecognitionService} from "@flow/services/voice-recongnition/voicerecognition.service";

@Component({
  selector: 'flowbot-chat-actions',
  standalone: true,
	imports: [
		MatIcon,
		MatIconButton,
		CdkCopyToClipboard
	],
  templateUrl: './flowbot-chat-actions.component.html',
  styleUrl: './flowbot-chat-actions.component.scss'
})
export class FlowbotChatActionsComponent {
	messageContent: InputSignal<string> = input.required<string>();
	isAutoPlay: InputSignal<boolean> = input.required<boolean>();
	private voiceService = inject(VoiceRecognitionService);

	playMessage() {

		this.voiceService.speak(this.messageContent());
	}
}
