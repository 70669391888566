<div class="sources-container">
    <a
        *ngFor="let source of sources"
        href="javascript:void(0)"
        class="source-link"
        (click)="onSourceClick(source.pageNumber)"
    >
        {{ source.fileName }}
    </a>
</div>
