<div class="flex flex-col w-full h-full pt-16">
	<div class="flex justify-center items-center">
		<div class="flex h-full flex-col items-center justify-center ">
			<!--<ng-lottie [options]="options" class="flex justify-center" width="72px"></ng-lottie>-->
			<div class="mb-5 text-2xl font-medium">What can flowBot do for you?</div>
		</div>
	</div>
	<!--<div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8">
		<div *ngFor="let hint of displayedHints" class="border rounded-xl p-6 flex flex-col hover:bg-blend-darken hover:cursor-pointer " matTooltip="Ask flowBot">
			<span class="text-lg">{{ hint.title }}</span>
			<span class="text-sm text-secondary">{{ hint.subTitle }}</span>
		</div>
	</div>-->
</div>
