import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'new-chat-hints',
    standalone: true,
    imports: [],
    templateUrl: './new-chat-hints.component.html',
    styleUrl: './new-chat-hints.component.scss',
})
export class NewChatHintsComponent implements OnInit {
    displayedHints: any[] = [];
    hints: any[] = [
        {
            id: 1,
            title: 'Learn About Inverters',
            subTitle: 'Show the technical specs of String Inverters',
        },
        {
            id: 2,
            title: 'Best Customer',
            subTitle: 'Find out who has made the most purchases this year.',
        },
        {
            id: 3,
            title: 'Monthly Sales',
            subTitle: 'Get the total sales for the last month.',
        },
        {
            id: 4,
            title: 'Generate Invoice',
            subTitle: 'Create a new invoice for recent orders.',
        },
        {
            id: 5,
            title: 'Payment Status',
            subTitle: 'Check the payment status for all outstanding invoices.',
        },
        {
            id: 6,
            title: 'Order Trends',
            subTitle: 'Analyze order trends over the past quarter.',
        },
        {
            id: 7,
            title: 'Factory Performance',
            subTitle: 'Review performance metrics for each factory.',
        },
        {
            id: 8,
            title: 'Revenue Forecast',
            subTitle: 'Forecast revenue based on current order data.',
        },
        {
            id: 9,
            title: 'Customer Insights',
            subTitle: 'Dive into customer behavior and preferences.',
        },
        {
            id: 10,
            title: 'Quote Conversion',
            subTitle: 'Track conversion rates from quotes to actual orders.',
        },
    ];

    ngOnInit() {
        //this.displayedHints = this.shuffleHints(this.hints).slice(0, 4);
    }

    shuffleHints(hints: any[]): any[] {
        return hints.sort(() => 0.5 - Math.random());
    }
}
