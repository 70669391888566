import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import * as navigationTypes from "../../../navigation.types";
import {FlowVerticalNavigationComponent} from "../../vertical.component";
import {FlowNavigationService} from "../../../navigation.service";

@Component({
	selector: 'flow-vertical-navigation-spacer-item',
	templateUrl: './spacer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass],
})
export class FlowVerticalNavigationSpacerItemComponent implements OnInit, OnDestroy {
	@Input() item: navigationTypes.FlowNavigationItem;
    @Input() name: string;

    private _flowVerticalNavigationComponent: FlowVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _flowNavigationService: FlowNavigationService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the parent navigation component
        this._flowVerticalNavigationComponent = this._flowNavigationService.getComponent(this.name);

        // Subscribe to onRefreshed on the navigation component
        this._flowVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll),
        ).subscribe(() =>
        {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
