import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'empty-layout',
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: './empty-layout.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyLayoutComponent { }
