<div class="suggestions-container flex flex-row gap-3 w-full flex-wrap">
    <button
        *ngFor="let suggestion of questionSuggestions"
        mat-flat-button
        class="suggestion-button"
        (click)="onSuggestionClick(suggestion.content)"
    >

        <mat-icon class="material-symbols-rounded">lightbulb</mat-icon>
        {{ suggestion.title }}
    </button>
    <p class="text-sm font-semibold text-secondary">Suggestions may not always be relevant or return a response.</p>
</div>
