import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';

import {AuthService} from "../../services/auth/auth.service";
import {authInterceptor} from "../../interceptors/auth.interceptor";


export const provideAuth = (): Array<Provider | EnvironmentProviders> =>
{

    return [
        provideHttpClient(withInterceptors([authInterceptor])),
        {
            provide : ENVIRONMENT_INITIALIZER,
            useValue: () => inject(AuthService),
            multi   : true,
        },
    ];
};
