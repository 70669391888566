import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {HotkeyDirective} from "@flow/directives/hotkeys/hotkey.directive";

@Component({
  selector: 'flowbot-button',
  standalone: true,
	imports: [
		MatButton,
		HotkeyDirective
	],
  templateUrl: './flowbot-button.component.html',
  styles: [
	  `

	  `
  ],
	encapsulation: ViewEncapsulation.None
})
export class FlowbotButtonComponent {
	@Output() public onClick: EventEmitter<any> = new EventEmitter();

	openFlowBot() {
		this.onClick.emit();
	}
}
