<div class="flex flex-row items-center">
	<button mat-icon-button [cdkCopyToClipboard]="messageContent()">
		<mat-icon class="material-symbols-rounded icon-size-5">content_copy</mat-icon>
	</button>
	<button mat-icon-button >
		<mat-icon class="material-symbols-rounded icon-size-5">recommend</mat-icon>
	</button>
	@if(!isAutoPlay()){
		<button mat-icon-button (click)="playMessage()">
			<mat-icon class="material-symbols-rounded icon-size-5">volume_up</mat-icon>
		</button>
	}

</div>
