import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Sanitizer,
	ViewChild
} from '@angular/core';
import {Subject} from "rxjs";
import {FormsModule} from "@angular/forms";
import {FlowbotToolbarComponent} from "../flowbot-toolbar/flowbot-toolbar.component";
import {FlowbotInputComponent} from "../flowbot-input/flowbot-input.component";
import {FlowbotMessagesComponent} from "../flowbot-messages/flowbot-messages.component";
import {NewChatHintsComponent} from "../new-chat-hints/new-chat-hints.component";

import {Router} from "@angular/router";
import {ChatsService, FlowbotDrawerService} from '@flow/services';
export interface Message {
  type: string;
  content: string;
}

@Component({
	selector: 'flowbot-chat-dialog',
	standalone: true,
	imports: [
		FormsModule,
		FlowbotToolbarComponent,
		FlowbotInputComponent,
		FlowbotMessagesComponent,
		NewChatHintsComponent,
	],
	templateUrl: './flowbot-chat.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class FlowbotChatComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('messageContainer') messageContainer: ElementRef;
	@Input() chatId: string | null = null;
	@Input() searchInput: string;
	@Output() drawerToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() currentUrl: string = '';
	messages: Message[] = [];
	loading = false;
	private _chatService = inject(ChatsService);
	private _unsubscribeAll: Subject<any>;
    questionSuggestions: any;
    sources: any;
    disabled: boolean;

	constructor(
		private sanitizer: Sanitizer,
		private _changeDetectorRef: ChangeDetectorRef,
		private _router: Router,
		private _flowDrawerService: FlowbotDrawerService,
	) {
		this._unsubscribeAll = new Subject();

	}

	ngOnInit(): void {
		this._chatService.triggerFunction$.subscribe((message: string) => {
			this.chatRequest(message);
		});
	}

	ngAfterViewInit(): void {
		this._changeDetectorRef.detectChanges();
	}


	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}


    chatRequest(event: any) {
        this.disabled = true;
        this.loading = true;

        // Add user message to the chat
        this.messages.push({
            content: event,
            type: 'user',
        });

        // Placeholder for the bot's response
        this.messages.push({
            content: '',
            type: 'flowBot',
        });

        const messageRequest = {
            chatId: this.chatId,
            currentUrl: this.currentUrl,
            limit: 10,
            maxTokens: 50000,
            query: event,
        };

        this._chatService
            .streamFlowAssistant(
                messageRequest.chatId,
                messageRequest.currentUrl,
                messageRequest.limit,
                messageRequest.maxTokens,
                messageRequest.query
            )
            .subscribe({
                next: (chunk) => {
                    if (this.chatId === null && chunk.data.flowAssistant.context) {

                        this.chatId = chunk.data.flowAssistant.context.chat_id;
                        this._changeDetectorRef.detectChanges();
                    }

                    const flowAssistantData = chunk.data.flowAssistant;
                    this._changeDetectorRef.detectChanges();
                    // Handle content updates
                    const lastMessage = this.messages.slice(-1)[0];
                    if (flowAssistantData.content) {
                        this.loading = false;
                        lastMessage.content += flowAssistantData.content;
                        this._changeDetectorRef.detectChanges();
                    }

                    // Extract and update suggestions
                    if (flowAssistantData.questionSuggestions) {
                        this.questionSuggestions = flowAssistantData.questionSuggestions;
                        this._changeDetectorRef.detectChanges();
                    }
                    // Extract and update sources
                    if (flowAssistantData.sources) {
                        this.sources = flowAssistantData.sources;
                        this._changeDetectorRef.detectChanges();
                    }

                    this._changeDetectorRef.detectChanges();
                    this._changeDetectorRef.markForCheck();
                },
                error: (err) => {
                    this.loading = false;
                    const lastMessage = this.messages.slice(-1)[0];
                    lastMessage.content = 'Error Retrieving Response. Please try again.';
                    this._changeDetectorRef.detectChanges();
                    console.error('Streaming error:', err);
                },
                complete: () => {
                    /*const lastMessage = this.messages.slice(-1)[0];
                    lastMessage.content = this._chatService.format(lastMessage.content);*/
                    this.disabled = false;
                    //this.scrollToBottom();
                    this._changeDetectorRef.detectChanges();
                },
            });
    }



    scrollToBottom(): void {
        console.log('scrollToBottom');
		setTimeout(() => {
			this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
		}, 0);
	}


	navigateToFullChat() {
		this._router.navigate(['/flowbot'], { queryParams: { chatId: this.chatId } }).then(result => {
			this._flowDrawerService.toggleDrawer();
		});
	}
}
