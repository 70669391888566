import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);

    // Handle HTTP requests
    if (!req.url.includes('/graphql')) {
        return next(req);
    }

    return authService.determineRefreshNeeded().pipe(
        switchMap((authenticated) => {
            if (!authenticated) {
                // Sign out
                authService.signOut();

                // Reload the app
                location.reload();
            }

            const newReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${authService.accessToken}`),
            });

            return next(newReq);
        }),
    );
};


