import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as flowMenuButtonTypes from "./flow-menu-button.types";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatDivider} from "@angular/material/divider";
import {MatIconButton} from "@angular/material/button";

@Component({
  selector: 'flow-menu-button',
  standalone: true,
  imports: [
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatDivider,
    MatMenuTrigger,
    MatIconButton
  ],
  templateUrl: './flow-menu-button.component.html',
  styles: [
    `
      .button-image {
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }
    `
  ]
})
export class FlowMenuButtonComponent {
  @Input() menuConfig!: flowMenuButtonTypes.MenuConfig;
  @Output() menuConfigChange = new EventEmitter();
  defaultIcon: 'defaultIcon';

  getButtonClass(buttonType: flowMenuButtonTypes.ButtonType): string {
    switch (buttonType) {
      case 'matIconButton':
        return 'mat-icon-button';
      case 'matSolidButton':
        return 'mat-raised-button';
      case 'matStrokeButton':
        return 'mat-stroked-button';
      default:
        return '';
    }
  }

  triggerClickEvent(action: () => void) {
    this.menuConfigChange.emit(action);
  }
}
